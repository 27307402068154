.benefits {
  background: rgba(255, 255, 255, 1);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding-top: 96px;
  padding-bottom: 96px;
  position: relative;
  justify-self: flex-start;
}
.benefits > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.benefits > :global(.__wab_flex-container) > *,
.benefits > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.benefits > :global(.__wab_flex-container) > picture > img,
.benefits
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .benefits {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.wrapper5 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1216px;
  min-width: 0;
}
.wrapper5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.wrapper5 > :global(.__wab_flex-container) > *,
.wrapper5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper5 > :global(.__wab_flex-container) > picture > img,
.wrapper5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper5 {
    padding: 0px 16px;
  }
}
.top6 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.top6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.top6 > :global(.__wab_flex-container) > *,
.top6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top6 > :global(.__wab_flex-container) > picture > img,
.top6 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .top6 {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .top6 > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .top6 > :global(.__wab_flex-container) > *,
  .top6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .top6 > :global(.__wab_flex-container) > picture > img,
  .top6
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.sectionHeader:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.col6 {
  height: 96px;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.role {
  width: 362px;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
}
.role > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.role > :global(.__wab_flex-container) > *,
.role > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.role > :global(.__wab_flex-container) > picture > img,
.role > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .role {
    align-self: flex-start;
    max-width: 362px;
    width: 100%;
    min-width: 0;
    padding: 0px 16px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .role > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.tagline {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.roleDropdown:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
}
.freeBox {
  position: relative;
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
  transition-property: all;
  opacity: 1;
  align-self: stretch;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  display: flex;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-duration: 0.3s;
}
