.caseStudyBanner {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  position: relative;
  max-width: 1184px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  overflow: hidden;
  display: flex;
  width: 100%;
  background: linear-gradient(#990000cc, #990000cc),
    url("https://site-assets.plasmic.app/f1e325d98134ca1f802fb80d131a9155.jpg")
      top 50% left 50% / cover repeat;
  min-width: 0;
  border-radius: 16px;
}
@media (max-width: 1024px) {
  .caseStudyBanner {
    background: url("https://site-assets.plasmic.app/3474a52b61ed67439f67b9edf8d09980.png")
        top 30% left 50% / cover no-repeat padding-box border-box,
      #990000cc;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyBanner {
    background: linear-gradient(#990000cc, #990000cc),
      url("https://site-assets.plasmic.app/e237077e690d17c421e6aff15c05b211.jpg")
        top 40% left 50% / 728px 700px no-repeat;
  }
}
.caseStudyBannertype_meharry {
  background: url("https://site-assets.plasmic.app/53c4a17f061a37f742bc16efcae932b6.png")
      top 30% left 50% / cover no-repeat padding-box border-box,
    #520026cc;
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyBannertype_meharry {
    background: url("https://site-assets.plasmic.app/53c4a17f061a37f742bc16efcae932b6.png")
        left 40% top 0% / cover no-repeat,
      #520026cc;
  }
}
.caseStudyBannertype_uconn {
  background: linear-gradient(#053c7fcc, #053c7fcc),
    url("https://site-assets.plasmic.app/f8c239950203d59d2d4e3f3a2629f843.jpg")
      0% 0% / cover no-repeat;
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyBannertype_uconn {
    background: linear-gradient(#053c7fcc, #053c7fcc),
      url("https://site-assets.plasmic.app/8a9e48e3447c3dce67242837c8ad7c26.jpg")
        top 0px left -340px / cover no-repeat padding-box border-box;
  }
}
.caseStudyBannertype_usc {
  background: linear-gradient(#990000cc, #990000cc),
    url("https://site-assets.plasmic.app/f1e325d98134ca1f802fb80d131a9155.jpg")
      top 50% left 50% / cover no-repeat;
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudyBannertype_usc {
    background: linear-gradient(#990000cc, #990000cc),
      url("https://site-assets.plasmic.app/34603566fe486415a147ef5115245789.jpg")
        top 50% left 50% / cover no-repeat;
  }
}
.desktop {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 400px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .desktop {
    display: flex;
    flex-direction: column;
    height: 512px;
  }
}
.top2 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  min-width: 0;
  padding: 32px;
}
.top2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.top2 > :global(.__wab_flex-container) > *,
.top2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top2 > :global(.__wab_flex-container) > picture > img,
.top2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .top2 {
    border-bottom: 1px solid #ffffff33;
  }
}
.quoteLogo {
  opacity: 0.8;
  width: 128px;
  height: 64px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .quoteLogo {
    width: 130px;
    height: 65px;
    flex-shrink: 0;
  }
}
.img {
  background: url("https://site-assets.plasmic.app/3c041a76dbda1b6746f49c618d1e50e7.png")
    center center / cover no-repeat;
  transform: translate(0.3329999999999984px, -0.33399999999999963px);
  width: 135.41%;
  height: 135.93%;
  display: block;
  position: absolute;
  top: -11.33px;
  left: -22.67px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img {
    width: 130%;
    height: 130%;
  }
}
.imgtype_meharry {
  background: url("https://site-assets.plasmic.app/9296a1b4acab4f9f50187da4c4f3ec06.png")
    center center / cover no-repeat;
  left: -29.33px;
  top: -11.33px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .imgtype_meharry {
    left: -31.33px;
  }
}
.imgtype_uconn {
  background: url("https://site-assets.plasmic.app/ddf6929d124fa8c67eeae23bb164c7be.png")
    center center / cover no-repeat;
  left: -45.33px;
  top: -11.33px;
}
.titleContainer {
  align-self: stretch;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-width: 0;
}
.title2 {
  font-family: var(--rubik);
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  width: 640px;
}
@media (max-width: 1024px) {
  .title2 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .title2 {
    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
    width: 100%;
    min-width: 0;
  }
}
.stats {
  width: 256px;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  flex-shrink: 0;
  min-height: 0;
  border-left: 1px solid #ffffff33;
}
@media (min-width: 0px) and (max-width: 768px) {
  .stats {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
.block {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  padding: 32px;
  border-bottom: 1px solid #ffffff33;
}
.block > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.block > :global(.__wab_flex-container) > *,
.block > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.block > :global(.__wab_flex-container) > picture > img,
.block > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .block {
    border-bottom-width: 0px;
    border-left-width: 0px;
    width: 100%;
    min-width: 0;
    border-top: 0px solid #ffffff33;
    border-right: 1px solid #ffffff33;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .block > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.val {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  align-self: stretch;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .val {
    font-size: 28px;
    line-height: 40px;
  }
}
.desc {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  align-self: stretch;
  position: relative;
}
.block2 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  padding: 32px;
}
.block2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.block2 > :global(.__wab_flex-container) > *,
.block2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.block2 > :global(.__wab_flex-container) > picture > img,
.block2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .block2 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .block2 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.val2 {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  align-self: stretch;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .val2 {
    font-size: 28px;
    line-height: 40px;
  }
}
.desc2 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  align-self: stretch;
  position: relative;
}
