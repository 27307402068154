.accordionSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  min-width: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
}
.accordionSectionHeader__tsVZ:global(.__wab_instance) {
  position: relative;
}
.transitionWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  opacity: 0;
  height: 0px;
  transform: translateX(0px) translateY(-25px) translateZ(0px);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-duration: 0.3s;
}
.transitionWrapperactive {
  opacity: 1;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  height: auto;
}
.freeBox__tzB2R {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px;
  border-bottom: 1px solid var(--token-NagwnEgqYDVz);
}
.freeBox__tzB2R > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__tzB2R > :global(.__wab_flex-container) > *,
.freeBox__tzB2R > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tzB2R > :global(.__wab_flex-container) > picture > img,
.freeBox__tzB2R
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox__tiCnl {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: url("https://site-assets.plasmic.app/586ae0424acd4b66ca75fce77ee81fa1.svg")
    top 50% left 50% / cover repeat;
  width: 100%;
  height: 244px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox__lmruD {
  display: flex;
  flex-direction: column;
  position: relative;
}
.freeBox__lmruD > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__lmruD > :global(.__wab_flex-container) > *,
.freeBox__lmruD > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lmruD > :global(.__wab_flex-container) > picture > img,
.freeBox__lmruD
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.h4___9XokW {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-vF1ggEL4NG72);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.p___3Lt {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__krDtu {
  display: flex;
  flex-direction: column;
  position: relative;
}
.freeBox__krDtu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__krDtu > :global(.__wab_flex-container) > *,
.freeBox__krDtu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__krDtu > :global(.__wab_flex-container) > picture > img,
.freeBox__krDtu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.h6__njRjY {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__lQEr {
  display: flex;
  flex-direction: row;
  position: relative;
}
.freeBox__lQEr > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.freeBox__lQEr > :global(.__wab_flex-container) > *,
.freeBox__lQEr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lQEr > :global(.__wab_flex-container) > picture > img,
.freeBox__lQEr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.text__k3Ge9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul___9Uz7F {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.text__hrwvh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul__pfqnY {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
