.items {
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.items > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.items > :global(.__wab_flex-container) > *,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items > :global(.__wab_flex-container) > picture > img,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 64px;
}
.desktopWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  height: auto;
}
.desktopWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.desktopWrapper > :global(.__wab_flex-container) > *,
.desktopWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desktopWrapper > :global(.__wab_flex-container) > picture > img,
.desktopWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.row5 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.row5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row5 > :global(.__wab_flex-container) > *,
.row5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row5 > :global(.__wab_flex-container) > picture > img,
.row5 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.textWithIcon {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon > :global(.__wab_flex-container) > *,
.textWithIcon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon > :global(.__wab_flex-container) > picture > img,
.textWithIcon
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__loTpS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__loTpS > picture > img {
  object-fit: cover;
}
.heading {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text11 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon2 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.textWithIcon2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon2 > :global(.__wab_flex-container) > *,
.textWithIcon2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon2 > :global(.__wab_flex-container) > picture > img,
.textWithIcon2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon2 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__mtntD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__mtntD > picture > img {
  object-fit: cover;
}
.heading2 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text22 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon3 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon3 > :global(.__wab_flex-container) > *,
.textWithIcon3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon3 > :global(.__wab_flex-container) > picture > img,
.textWithIcon3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon3 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__r9XHr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__r9XHr > picture > img {
  object-fit: cover;
}
.heading3 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text5 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.row6 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.row6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row6 > :global(.__wab_flex-container) > *,
.row6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row6 > :global(.__wab_flex-container) > picture > img,
.row6 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.textWithIcon4 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon4 > :global(.__wab_flex-container) > *,
.textWithIcon4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon4 > :global(.__wab_flex-container) > picture > img,
.textWithIcon4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon4 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__pkUXb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__pkUXb > picture > img {
  object-fit: cover;
}
.heading4 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text6 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon5 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon5 > :global(.__wab_flex-container) > *,
.textWithIcon5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon5 > :global(.__wab_flex-container) > picture > img,
.textWithIcon5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon5 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__nYGjI {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__nYGjI > picture > img {
  object-fit: cover;
}
.heading5 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text7 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon6 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon6 > :global(.__wab_flex-container) > *,
.textWithIcon6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon6 > :global(.__wab_flex-container) > picture > img,
.textWithIcon6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon6 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__h2Sx7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__h2Sx7 > picture > img {
  object-fit: cover;
}
.heading6 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text8 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.tabletWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  height: auto;
}
.tabletWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.tabletWrapper > :global(.__wab_flex-container) > *,
.tabletWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.tabletWrapper > :global(.__wab_flex-container) > picture > img,
.tabletWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
@media (max-width: 1024px) {
  .tabletWrapper {
    display: flex;
  }
}
.row7 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.row7 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row7 > :global(.__wab_flex-container) > *,
.row7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row7 > :global(.__wab_flex-container) > picture > img,
.row7 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.textWithIcon7 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon7 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon7 > :global(.__wab_flex-container) > *,
.textWithIcon7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon7 > :global(.__wab_flex-container) > picture > img,
.textWithIcon7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon7 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__jFi64 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__jFi64 > picture > img {
  object-fit: cover;
}
.heading7 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text12 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon8 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.textWithIcon8 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon8 > :global(.__wab_flex-container) > *,
.textWithIcon8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon8 > :global(.__wab_flex-container) > picture > img,
.textWithIcon8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon8 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__uui20 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__uui20 > picture > img {
  object-fit: cover;
}
.heading8 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text9 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.row8 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.row8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row8 > :global(.__wab_flex-container) > *,
.row8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row8 > :global(.__wab_flex-container) > picture > img,
.row8 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.textWithIcon9 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon9 > :global(.__wab_flex-container) > *,
.textWithIcon9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon9 > :global(.__wab_flex-container) > picture > img,
.textWithIcon9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon9 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__rAvi5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__rAvi5 > picture > img {
  object-fit: cover;
}
.heading9 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text10 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon10 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon10 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon10 > :global(.__wab_flex-container) > *,
.textWithIcon10 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon10 > :global(.__wab_flex-container) > picture > img,
.textWithIcon10
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon10 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img___1DAoD {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img___1DAoD > picture > img {
  object-fit: cover;
}
.heading10 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text13 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.row9 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.row9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row9 > :global(.__wab_flex-container) > *,
.row9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row9 > :global(.__wab_flex-container) > picture > img,
.row9 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.textWithIcon11 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon11 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon11 > :global(.__wab_flex-container) > *,
.textWithIcon11 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon11 > :global(.__wab_flex-container) > picture > img,
.textWithIcon11
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon11 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__fMrt1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__fMrt1 > picture > img {
  object-fit: cover;
}
.heading11 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text14 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon12 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon12 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon12 > :global(.__wab_flex-container) > *,
.textWithIcon12 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon12 > :global(.__wab_flex-container) > picture > img,
.textWithIcon12
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon12 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__tQnl6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__tQnl6 > picture > img {
  object-fit: cover;
}
.heading12 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text15 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.mobileWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  height: auto;
  padding: 0px 16px;
}
.mobileWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.mobileWrapper > :global(.__wab_flex-container) > *,
.mobileWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mobileWrapper > :global(.__wab_flex-container) > picture > img,
.mobileWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.textWithIcon13 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon13 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon13 > :global(.__wab_flex-container) > *,
.textWithIcon13 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon13 > :global(.__wab_flex-container) > picture > img,
.textWithIcon13
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon13 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__oSkog {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__oSkog > picture > img {
  object-fit: cover;
}
.heading13 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text16 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon14 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.textWithIcon14 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon14 > :global(.__wab_flex-container) > *,
.textWithIcon14 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon14 > :global(.__wab_flex-container) > picture > img,
.textWithIcon14
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon14 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__iDqet {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__iDqet > picture > img {
  object-fit: cover;
}
.heading14 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.freeBox {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  display: none;
}
.text17 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon15 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon15 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon15 > :global(.__wab_flex-container) > *,
.textWithIcon15 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon15 > :global(.__wab_flex-container) > picture > img,
.textWithIcon15
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon15 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__aSi1K {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__aSi1K > picture > img {
  object-fit: cover;
}
.heading15 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text18 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon16 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon16 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon16 > :global(.__wab_flex-container) > *,
.textWithIcon16 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon16 > :global(.__wab_flex-container) > picture > img,
.textWithIcon16
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon16 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img___4L4V {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img___4L4V > picture > img {
  object-fit: cover;
}
.heading16 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text19 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon17 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon17 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon17 > :global(.__wab_flex-container) > *,
.textWithIcon17 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon17 > :global(.__wab_flex-container) > picture > img,
.textWithIcon17
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon17 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__vSsMg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__vSsMg > picture > img {
  object-fit: cover;
}
.heading17 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text20 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.textWithIcon18 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.textWithIcon18 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.textWithIcon18 > :global(.__wab_flex-container) > *,
.textWithIcon18 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.textWithIcon18 > :global(.__wab_flex-container) > picture > img,
.textWithIcon18
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.featureIcon18 {
  background: rgba(224, 243, 255, 1);
  width: 64px;
  height: 64px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__eWPu0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 32px;
  height: 32px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__eWPu0 > picture > img {
  object-fit: cover;
}
.heading18 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
.text21 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
}
