.roleDropdown {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  background: #ffffff;
  min-width: 0;
}
.popoverCore:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.input {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(196, 203, 210, 1);
  transition-property: transform;
  transition-duration: 0.3s;
  cursor: pointer;
  transform: none;
  z-index: 2;
  min-width: 0;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.3s;
  border-radius: 8px;
  padding: 8px 16px;
}
.input > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.input > :global(.__wab_flex-container) > *,
.input > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.input > :global(.__wab_flex-container) > picture > img,
.input > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.roleDropdown:hover .input {
  background: var(--token-w1v4JEx-uq2e);
}
.roleDropdown:active .input {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  background: var(--token-w1v4JEx-uq2e);
}
.directorAvatar {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 128px;
}
.img2 {
  background: url("https://site-assets.plasmic.app/2201c4034f05b5f19f675985564ce6d2.jpg")
    center center / cover no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
}
.svg__wgr2E {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #81e733;
  mix-blend-mode: hue;
  width: 40px;
  height: 40px;
  display: block;
  top: 0%;
  left: 0%;
}
.officerAvatar {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 128px;
}
.img3 {
  background: url("https://site-assets.plasmic.app/09345847c7f63b6495d7eb3b8da14821.svg")
    center center / cover no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
}
.svg__fiA3J {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #8bcdf380;
  mix-blend-mode: hue;
  width: 40px;
  height: 40px;
  display: block;
  top: 0%;
  left: 0%;
}
.adminAvatar {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 128px;
}
.img4 {
  background: url("https://site-assets.plasmic.app/b3f05cd4bacd09e79beef0f8e3057194.jpg")
    center center / cover no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
}
.text10 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.role2 {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  user-select: none;
}
.name {
  color: var(--token-sJr9sbZwbyqK);
  font-family: var(--rubik);
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
  user-select: none;
}
.rightIcon {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  padding: 0px;
  border: 0px solid transparent;
}
.svg__bHqhZ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  color: var(--token-sJr9sbZwbyqK);
  transition-property: all;
  transition-duration: 0.3s;
  cursor: pointer;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
}
.dropdown {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
  opacity: 1;
  background: #ffffff;
  width: 360px;
  margin-top: -4px;
  border-radius: 8px;
  padding: 4px;
}
.dropdown > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.dropdown > :global(.__wab_flex-container) > *,
.dropdown > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.dropdown > :global(.__wab_flex-container) > picture > img,
.dropdown
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .dropdown {
    width: 326px;
  }
}
@media (max-width: 576px) {
  .dropdown {
    width: 78vw;
    max-width: 330px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
.homeBenefitsSectionRoleDropdownItem:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
