.productsSection {
  display: flex;
  flex-direction: column;
  background: var(--token-9T9NC2lhv1Ej);
  position: relative;
  width: 100%;
  max-width: 1216px;
  min-width: 0;
  padding: 168px 14px 96px;
}
.productsSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.productsSection > :global(.__wab_flex-container) > *,
.productsSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.productsSection > :global(.__wab_flex-container) > picture > img,
.productsSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .productsSection {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .productsSection {
    padding: 64px 16px;
  }
}
.productsProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.desktopWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.desktopWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.desktopWrapper > :global(.__wab_flex-container) > *,
.desktopWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desktopWrapper > :global(.__wab_flex-container) > picture > img,
.desktopWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.row {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  min-width: 0;
}
.row > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row > :global(.__wab_flex-container) > *,
.row > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row > :global(.__wab_flex-container) > picture > img,
.row > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.sectionHeader__zwejz:global(.__wab_instance) {
  max-width: 560px;
  position: relative;
  width: 100%;
  min-width: 0;
}
.row2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  min-width: 0;
}
.row2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row2 > :global(.__wab_flex-container) > *,
.row2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row2 > :global(.__wab_flex-container) > picture > img,
.row2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
}
.accordion__gj1LP:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.accordionSection__y0WlQ:global(.__wab_instance) {
  position: relative;
}
.accordionSectionHeader__n7NDu:global(.__wab_instance) {
  position: relative;
  z-index: 2;
}
.bodyWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px;
}
.bodyWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.bodyWrapper > :global(.__wab_flex-container) > *,
.bodyWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bodyWrapper > :global(.__wab_flex-container) > picture > img,
.bodyWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.imgWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-F-isEiSy-7QO);
  border-radius: 8px;
}
.accordionSectionSplashImage__xwrxt:global(.__wab_instance) {
  max-width: 100%;
}
.text {
  display: flex;
  flex-direction: column;
  position: relative;
}
.text > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.text > :global(.__wab_flex-container) > *,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text > :global(.__wab_flex-container) > picture > img,
.text > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.title {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-vF1ggEL4NG72);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.subtitle {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.features {
  display: flex;
  flex-direction: column;
  position: relative;
}
.features > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.features > :global(.__wab_flex-container) > *,
.features > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.features > :global(.__wab_flex-container) > picture > img,
.features
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.h6__fxBmc {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.list {
  display: flex;
  flex-direction: row;
  position: relative;
}
.list > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.list > :global(.__wab_flex-container) > *,
.list > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.list > :global(.__wab_flex-container) > picture > img,
.list > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.text___99P2Z {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul__aLQww {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.text__fadav {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul__xm0Da {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.rightColumn {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-self: flex-start;
  max-width: 373px;
  min-width: 0;
}
.rightColumn > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.rightColumn > :global(.__wab_flex-container) > *,
.rightColumn > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rightColumn > :global(.__wab_flex-container) > picture > img,
.rightColumn
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.nav {
  box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: var(--token-9T9NC2lhv1Ej);
  max-width: 373px;
  max-height: 380px;
  min-width: 0;
  border-radius: 12px;
  padding: 8px 8px 8px 14px;
  border: 1px solid var(--token-7u3Fjf0ZO4pV);
}
.nav > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.nav > :global(.__wab_flex-container) > *,
.nav > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nav > :global(.__wab_flex-container) > picture > img,
.nav > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .nav {
    height: 320px;
    padding-right: 0px;
    flex-shrink: 0;
  }
}
@media (max-width: 1024px) {
  .nav > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.img__npJq5 {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__npJq5 > picture > img {
  object-fit: cover;
}
.homeProductsSectionQuote__p06R:global(.__wab_instance) {
  height: auto;
}
.sliderNavigation {
  width: 373px;
  height: auto;
  display: flex;
  flex-direction: row;
}
.sliderNavigation > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.sliderNavigation > :global(.__wab_flex-container) > *,
.sliderNavigation > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sliderNavigation > :global(.__wab_flex-container) > picture > img,
.sliderNavigation
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.sliderNavigationBullet {
  background: rgba(7, 126, 178, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet2 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet3 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet4 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet5 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet6 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet7 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet8 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet9 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.mobileWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.mobileWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.mobileWrapper > :global(.__wab_flex-container) > *,
.mobileWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mobileWrapper > :global(.__wab_flex-container) > picture > img,
.mobileWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
@media (max-width: 1024px) {
  .mobileWrapper {
    display: flex;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobileWrapper {
    display: flex;
  }
}
.row3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  min-width: 0;
  padding: 0px;
}
.row3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row3 > :global(.__wab_flex-container) > *,
.row3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row3 > :global(.__wab_flex-container) > picture > img,
.row3 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .row3 {
    padding: 0px;
  }
}
.sectionHeader__ttzZo:global(.__wab_instance) {
  max-width: 560px;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox {
    box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
    width: 100%;
    min-width: 0;
    border-radius: 12px;
    padding: 8px;
    border: 1px solid var(--token-7u3Fjf0ZO4pV);
  }
}
@media (max-width: 576px) {
  .freeBox {
    padding: 0px;
    border-width: 0px;
  }
}
.nav2 {
  box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: var(--token-9T9NC2lhv1Ej);
  align-self: center;
  min-width: 0;
  border-radius: 12px;
  padding: 8px;
  border: 0px solid var(--token-7u3Fjf0ZO4pV);
}
.nav2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.nav2 > :global(.__wab_flex-container) > *,
.nav2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nav2 > :global(.__wab_flex-container) > picture > img,
.nav2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .nav2 {
    height: 264px;
    background: url("https://site-assets.plasmic.app/50c9c8d69bd8c7b874e46fa8bbfe8c98.svg")
        top 50% left -150px / 140% 100% no-repeat,
      var(--token-9T9NC2lhv1Ej);
    width: 100%;
    max-width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .nav2 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (max-width: 576px) {
  .nav2 {
    background: var(--token-9T9NC2lhv1Ej);
    height: auto;
    padding-right: 0px;
  }
}
.img5 {
  background: #ffffff;
  width: 100%;
  height: 372px;
  display: block;
  position: relative;
  max-width: 357px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 12px;
}
.logoIcon2 {
  transform: scale(1, -1);
  width: 17.92%;
  height: 17.2%;
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 62.36%;
  left: 41.17%;
}
.svg__fWWg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  width: 63.855px;
  height: 46.239px;
  display: block;
  top: 0px;
  left: 0px;
}
.svg__iUy56 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  transform: translate(0.10799999999999965px, 0.26399999999999935px);
  width: 60px;
  height: 41px;
  display: block;
  top: 35.93%;
  left: 6.25%;
}
.svg__vcmhv {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(-0.05600000000000005px, 0.16300000000000026px);
  width: 62.045px;
  height: 44.366px;
  display: block;
  top: 6px;
  left: 2px;
}
.svg__rtLwe {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  transform: translate(-0.16599999999999682px, 0.3960000000000008px);
  width: 4.307px;
  height: 8.015px;
  display: block;
  top: 9px;
  left: 43px;
}
.svg__rszGd {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  transform: translate(-0.16599999999999682px, 0.3960000000000008px);
  width: 4.307px;
  height: 8.015px;
  display: block;
  top: 9px;
  left: 43px;
}
.svg__gp0It {
  position: absolute;
  object-fit: cover;
  color: #e9f7ff;
  width: 100%;
  height: 164px;
  display: block;
  transform-origin: top left;
  transform: scale(1, -1);
  top: 372px;
  left: 0px;
  max-width: 178px;
  min-width: 0;
}
.img__rRq4I {
  position: absolute;
  object-fit: cover;
  width: 100%;
  display: block !important;
  transform-origin: top left;
  transform: scale(1, -1);
  top: 372px;
  left: 186px;
  height: 268px;
  max-width: 171px;
  min-width: 0;
}
.img__rRq4I > picture > img {
  object-fit: cover;
}
.svg___39XK {
  position: absolute;
  object-fit: cover;
  color: #dff3ff;
  width: 100%;
  height: 200px;
  display: block;
  top: 200px;
  left: 0px;
  max-width: 100%;
  transform-origin: top left;
  transform: scale(1, -1);
  min-width: 0;
}
.logo4 {
  width: 47.8%;
  height: 9.67%;
  display: block;
  position: absolute;
  top: 8.06%;
  left: 24.08%;
  overflow: hidden;
}
.img__tAbqz {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 170.666px;
  height: 29.831px;
  display: block !important;
  top: 11.11%;
  left: 0%;
  overflow: hidden;
}
.img__tAbqz > picture > img {
  object-fit: cover;
}
.logo5 {
  width: 47.8%;
  height: 9.67%;
  display: block;
  position: absolute;
  top: 74.46%;
  left: 55.74%;
  overflow: hidden;
}
.img__kUaG {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  transform: translate(0px, 0.3330000000000002px);
  width: 146.663px;
  height: 30.015px;
  display: block !important;
  top: 13.88%;
  left: 0%;
  overflow: hidden;
}
.img__kUaG > picture > img {
  object-fit: cover;
}
.logo6 {
  width: 47.8%;
  height: 9.67%;
  display: block;
  position: absolute;
  top: 74.46%;
  left: 13.16%;
  overflow: hidden;
}
.img__ttifX {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  width: 81.331px;
  height: 31.118px;
  display: block !important;
  top: 11.11%;
  left: 0%;
  overflow: hidden;
}
.img__ttifX > picture > img {
  object-fit: cover;
}
.img__ypIGh {
  position: relative;
  object-fit: fill;
  width: 100%;
  height: 264px;
  margin-left: 0px;
  margin-top: calc(0px + 8px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
}
.img__ypIGh > picture > img {
  object-fit: fill;
}
@media (max-width: 1024px) {
  .img__ypIGh {
    margin-top: calc(0px + 8px) !important;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__ypIGh {
    object-fit: fill;
    width: 100%;
    height: 264px;
    margin-top: calc(0px + 8px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    position: relative;
    left: auto;
    top: auto;
    align-self: center;
    min-width: 0;
    flex-shrink: 0;
    border-radius: 8px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__ypIGh > picture > img {
    object-fit: fill;
  }
}
@media (max-width: 576px) {
  .img__ypIGh {
    object-fit: fill;
    height: 100%;
    margin-top: calc(0px + 8px) !important;
    min-height: 0;
    display: block !important;
  }
}
@media (max-width: 576px) {
  .img__ypIGh > picture > img {
    object-fit: fill;
  }
}
.row4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
  min-width: 0;
  padding: 0px;
}
.row4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row4 > :global(.__wab_flex-container) > *,
.row4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row4 > :global(.__wab_flex-container) > picture > img,
.row4 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .row4 {
    padding: 0px;
  }
}
.leftColumn2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
}
.accordion__um76U:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.accordionSection__kpDdj:global(.__wab_instance) {
  position: relative;
}
.accordionSectionHeader__zlygf:global(.__wab_instance) {
  position: relative;
  z-index: 2;
}
.bodyWrapper2 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px;
}
.bodyWrapper2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.bodyWrapper2 > :global(.__wab_flex-container) > *,
.bodyWrapper2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bodyWrapper2 > :global(.__wab_flex-container) > picture > img,
.bodyWrapper2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .bodyWrapper2 {
    padding: 32px 16px;
  }
}
.accordionSectionSplashImage__zu3Hs:global(.__wab_instance) {
  max-width: 100%;
  height: 244px;
  max-height: 244px;
  flex-shrink: 0;
}
.text2 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.text2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.text2 > :global(.__wab_flex-container) > *,
.text2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.text2 > :global(.__wab_flex-container) > picture > img,
.text2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.title2 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-vF1ggEL4NG72);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.subtitle2 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.features2 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.features2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.features2 > :global(.__wab_flex-container) > *,
.features2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.features2 > :global(.__wab_flex-container) > picture > img,
.features2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.h6__ik06C {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.list2 {
  display: flex;
  flex-direction: row;
  position: relative;
}
.list2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.list2 > :global(.__wab_flex-container) > *,
.list2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.list2 > :global(.__wab_flex-container) > picture > img,
.list2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .list2 {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .list2 > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .list2 > :global(.__wab_flex-container) > *,
  .list2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .list2 > :global(.__wab_flex-container) > picture > img,
  .list2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
@media (max-width: 576px) {
  .list2 {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .list2 > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (max-width: 576px) {
  .list2 > :global(.__wab_flex-container) > *,
  .list2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .list2 > :global(.__wab_flex-container) > picture > img,
  .list2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.text__yUkTt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul__lzURt {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.text___5Nfzb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul__rWzQr {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.quote {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 0px) and (max-width: 768px) {
  .quote {
    padding: 0px;
  }
}
.homeProductsSectionQuote__ufWhC:global(.__wab_instance) {
  height: 100%;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .homeProductsSectionQuote__ufWhC:global(.__wab_instance) {
    height: auto;
  }
}
.sliderNavigation2 {
  width: 373px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.sliderNavigation2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.sliderNavigation2 > :global(.__wab_flex-container) > *,
.sliderNavigation2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sliderNavigation2 > :global(.__wab_flex-container) > picture > img,
.sliderNavigation2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.sliderNavigationBullet10 {
  background: rgba(7, 126, 178, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet11 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet12 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet13 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet14 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet15 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet16 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet17 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBullet18 {
  background: rgba(223, 228, 233, 1);
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 64px;
}
