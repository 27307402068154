.loginSection {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 0;
}
.login {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1440px;
  min-width: 0;
}
.warning {
  background: var(--token-WnhL5TAc8eef);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  min-width: 0;
}
.warning > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.warning > :global(.__wab_flex-container) > *,
.warning > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.warning > :global(.__wab_flex-container) > picture > img,
.warning
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1216px;
  min-width: 0;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.icon {
  background: var(--token-WnhL5TAc8eef);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
}
.icon > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.icon > :global(.__wab_flex-container) > *,
.icon > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.icon > :global(.__wab_flex-container) > picture > img,
.icon > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.wrapper2 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  border-radius: 32px;
}
.wrapper2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.wrapper2 > :global(.__wab_flex-container) > *,
.wrapper2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper2 > :global(.__wab_flex-container) > picture > img,
.wrapper2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 16px;
  height: 16px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.text {
  font-family: var(--rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--token-6ihMHiXSX_i2);
  display: block;
  flex-grow: 1;
  position: relative;
}
.wrapper4 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--token-9T9NC2lhv1Ej);
  width: 100%;
  min-width: 0;
}
.wrapper4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.wrapper4 > :global(.__wab_flex-container) > *,
.wrapper4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper4 > :global(.__wab_flex-container) > picture > img,
.wrapper4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.wrapper3 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1216px;
  min-width: 0;
}
.wrapper3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.wrapper3 > :global(.__wab_flex-container) > *,
.wrapper3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper3 > :global(.__wab_flex-container) > picture > img,
.wrapper3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
.title {
  font-family: var(--rubik);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--token-sJr9sbZwbyqK);
  display: block;
  position: relative;
}
.loginForm:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.form {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.form > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.usernameProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.usernameInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 32px;
  width: 232px;
}
.passwordProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.passwordInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 32px;
  width: 232px;
}
.logInButton:global(.__wab_instance) {
  height: 32px;
  position: relative;
  width: 75px;
  flex-shrink: 0;
}
.svg__jxvgR {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__e26Lb {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.spinnerIcon:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 20px;
  height: 20px;
}
.svg__xiAoZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.link {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  text-align: right;
  position: relative;
  cursor: pointer;
}
