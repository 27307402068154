.solutions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: var(--token-9T9NC2lhv1Ej);
  width: 100%;
  min-width: 0;
}
.desktop {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 270px;
  padding-bottom: 24px;
  background: none;
  max-width: 1440px;
  margin-top: -230px;
  min-width: 0;
}
.desktop > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.desktop > :global(.__wab_flex-container) > *,
.desktop > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desktop > :global(.__wab_flex-container) > picture > img,
.desktop
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 1224px) {
  .desktop {
    background: url("https://site-assets.plasmic.app/b1e459c38a5792b5abc84835c2b04175.svg")
        top -190px right -80px / 700px 100% no-repeat,
      var(--token-9T9NC2lhv1Ej);
  }
}
@media (max-width: 1024px) {
  .desktop {
    background: var(--token-9T9NC2lhv1Ej);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1216px;
  z-index: 1;
  min-width: 0;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.row {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  min-width: 0;
}
.row > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row > :global(.__wab_flex-container) > *,
.row > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row > :global(.__wab_flex-container) > picture > img,
.row > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.col {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.col > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.col > :global(.__wab_flex-container) > *,
.col > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.col > :global(.__wab_flex-container) > picture > img,
.col > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.sectionHeader__mevV7:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  width: 560px;
}
.cards {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: auto;
  min-width: 0;
}
.cards > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.cards > :global(.__wab_flex-container) > *,
.cards > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cards > :global(.__wab_flex-container) > picture > img,
.cards > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.graduateCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader4 {
  background: linear-gradient(#00294ce6, #00294ce6),
    url("https://site-assets.plasmic.app/c1e77b3e2f2987fb91870984de76b25d.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 192px;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader4 > :global(.__wab_flex-container) > *,
.cardHeader4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader4 > :global(.__wab_flex-container) > picture > img,
.cardHeader4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-eqvPWoOG9mbc);
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content4 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.content4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content4 > :global(.__wab_flex-container) > *,
.content4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content4 > :global(.__wab_flex-container) > picture > img,
.content4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .content4 {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1024px) {
  .content4 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text4 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__sl1Db:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vDLxv {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yYDg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.physicianCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader {
  background: linear-gradient(#180038e6, #180038e6),
    url("https://site-assets.plasmic.app/1ee3490af06db8ab5c5f79d992c68936.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 192px;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader > :global(.__wab_flex-container) > *,
.cardHeader > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader > :global(.__wab_flex-container) > picture > img,
.cardHeader
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title2 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon2 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #9e40e7;
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.content > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .content {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1024px) {
  .content > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button___9Lpr:global(.__wab_instance) {
  max-width: 100%;
}
.svg__txksC {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__yE1R {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.nursingCard:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader2 {
  background: linear-gradient(#350d00e6, #350d00e6),
    url("https://site-assets.plasmic.app/c93a75da5d23ce5545f9b6c000da4cfd.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 192px;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader2 > :global(.__wab_flex-container) > *,
.cardHeader2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader2 > :global(.__wab_flex-container) > picture > img,
.cardHeader2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title3 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon3 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-JYEEwSwHupuU);
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content2 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.content2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content2 > :global(.__wab_flex-container) > *,
.content2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content2 > :global(.__wab_flex-container) > picture > img,
.content2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .content2 {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1024px) {
  .content2 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text2 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__o2Knj:global(.__wab_instance) {
  max-width: 100%;
}
.svg__tWiT {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___3FaNp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.bgImage {
  position: absolute;
  object-fit: contain;
  max-width: 100%;
  top: -80px;
  width: 850px;
  height: 815px;
  right: -110px;
  flex-shrink: 0;
}
.bgImage > picture > img {
  object-fit: contain;
}
@media (max-width: 1224px) {
  .bgImage {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .bgImage {
    display: none !important;
  }
}
.tablet {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 270px;
  padding-bottom: 24px;
  background: url("https://site-assets.plasmic.app/7f47badaf65154fa1135866dbeda3bf5.svg")
      top -200px right -5px / 700px 100% no-repeat,
    var(--token-9T9NC2lhv1Ej);
  max-width: 1440px;
  margin-top: -230px;
  min-width: 0;
}
.tablet > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.tablet > :global(.__wab_flex-container) > *,
.tablet > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.tablet > :global(.__wab_flex-container) > picture > img,
.tablet
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (max-width: 1224px) {
  .tablet {
    background: url("https://site-assets.plasmic.app/7f47badaf65154fa1135866dbeda3bf5.svg")
        top -200px right -60px / 700px 100% no-repeat,
      var(--token-9T9NC2lhv1Ej);
  }
}
@media (max-width: 1024px) {
  .tablet {
    background: var(--token-9T9NC2lhv1Ej);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .tablet {
    display: none;
  }
}
.wrapper2 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1216px;
  z-index: 1;
  min-width: 0;
}
.wrapper2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.wrapper2 > :global(.__wab_flex-container) > *,
.wrapper2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper2 > :global(.__wab_flex-container) > picture > img,
.wrapper2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.row2 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  min-width: 0;
}
.row2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row2 > :global(.__wab_flex-container) > *,
.row2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row2 > :global(.__wab_flex-container) > picture > img,
.row2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
.col2 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.col2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.col2 > :global(.__wab_flex-container) > *,
.col2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.col2 > :global(.__wab_flex-container) > picture > img,
.col2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 24px;
}
.sectionHeader__yIvH6:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  width: 560px;
}
.cards3 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: auto;
  min-width: 0;
}
.cards3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.cards3 > :global(.__wab_flex-container) > *,
.cards3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cards3 > :global(.__wab_flex-container) > picture > img,
.cards3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.graduateCard3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader7 {
  background: linear-gradient(#00294ce6, #00294ce6),
    url("https://site-assets.plasmic.app/427fbc81a9300279cd2e4f3010f12af0.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 192px;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader7 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader7 > :global(.__wab_flex-container) > *,
.cardHeader7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader7 > :global(.__wab_flex-container) > picture > img,
.cardHeader7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title7 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon7 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-eqvPWoOG9mbc);
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content7 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.content7 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content7 > :global(.__wab_flex-container) > *,
.content7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content7 > :global(.__wab_flex-container) > picture > img,
.content7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .content7 {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1024px) {
  .content7 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text7 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__wIMtU:global(.__wab_instance) {
  max-width: 100%;
}
.svg__qp4Zt {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__c8URp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.physicianCard3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader8 {
  background: linear-gradient(#180038e6, #180038e6),
    url("https://site-assets.plasmic.app/1ee3490af06db8ab5c5f79d992c68936.jpg")
      top 50% left 50% / cover repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 192px;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader8 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader8 > :global(.__wab_flex-container) > *,
.cardHeader8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader8 > :global(.__wab_flex-container) > picture > img,
.cardHeader8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title8 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #9e40e7;
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content8 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.content8 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content8 > :global(.__wab_flex-container) > *,
.content8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content8 > :global(.__wab_flex-container) > picture > img,
.content8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .content8 {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1024px) {
  .content8 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text8 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__kW4Ki:global(.__wab_instance) {
  max-width: 100%;
}
.svg__flkUm {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zKnRp {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.nursingCard3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.cardHeader9 {
  background: linear-gradient(#350d00e6, #350d00e6),
    url("https://site-assets.plasmic.app/c93a75da5d23ce5545f9b6c000da4cfd.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 192px;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader9 > :global(.__wab_flex-container) > *,
.cardHeader9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader9 > :global(.__wab_flex-container) > picture > img,
.cardHeader9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title9 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon9 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-JYEEwSwHupuU);
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content9 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.content9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content9 > :global(.__wab_flex-container) > *,
.content9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content9 > :global(.__wab_flex-container) > picture > img,
.content9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .content9 {
    height: 100%;
    min-height: 0;
  }
}
@media (max-width: 1024px) {
  .content9 > :global(.__wab_flex-container) {
    min-height: 0;
  }
}
.text9 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button___5Ctym:global(.__wab_instance) {
  max-width: 100%;
}
.svg__zsiAh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__anIjJ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.bgImage2 {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  top: -250px;
  width: 55%;
  right: 0px;
  display: none !important;
}
.bgImage2 > picture > img {
  object-fit: cover;
}
@media (max-width: 1224px) {
  .bgImage2 {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .bgImage2 {
    display: none !important;
  }
}
.mobile {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: var(--token-9T9NC2lhv1Ej);
  min-width: 0;
  display: none;
  padding: 64px 16px;
}
.mobile > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.mobile > :global(.__wab_flex-container) > *,
.mobile > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mobile > :global(.__wab_flex-container) > picture > img,
.mobile
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobile {
    display: flex;
  }
}
.sectionHeader__oaNpk:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: auto;
  min-width: 0;
}
.cards2 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
}
.cards2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.cards2 > :global(.__wab_flex-container) > *,
.cards2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cards2 > :global(.__wab_flex-container) > picture > img,
.cards2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.graduateCard2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.cardHeader5 {
  background: linear-gradient(#00294ce6, #00294ce6),
    url("https://site-assets.plasmic.app/427fbc81a9300279cd2e4f3010f12af0.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader5 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader5 > :global(.__wab_flex-container) > *,
.cardHeader5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader5 > :global(.__wab_flex-container) > picture > img,
.cardHeader5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title4 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-eqvPWoOG9mbc);
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content5 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 28px;
}
.content5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content5 > :global(.__wab_flex-container) > *,
.content5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content5 > :global(.__wab_flex-container) > picture > img,
.content5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.text5 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button___0VyIw:global(.__wab_instance) {
  max-width: 100%;
}
.svg__lw7Vh {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__zckz4 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.physicianCard2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader3 {
  background: linear-gradient(#180038e6, #180038e6),
    url("https://site-assets.plasmic.app/1ee3490af06db8ab5c5f79d992c68936.jpg")
      top 50% left 50% / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader3 > :global(.__wab_flex-container) > *,
.cardHeader3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader3 > :global(.__wab_flex-container) > picture > img,
.cardHeader3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title5 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #9e40e7;
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content3 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 28px;
}
.content3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content3 > :global(.__wab_flex-container) > *,
.content3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content3 > :global(.__wab_flex-container) > picture > img,
.content3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.text3 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__rrZyv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__pJ20Q {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__mblR8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.nursingCard2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.cardHeader6 {
  background: linear-gradient(#350d00e6, #350d00e6),
    url("https://site-assets.plasmic.app/c93a75da5d23ce5545f9b6c000da4cfd.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  border-radius: 12px;
  padding: 28px;
}
.cardHeader6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.cardHeader6 > :global(.__wab_flex-container) > *,
.cardHeader6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cardHeader6 > :global(.__wab_flex-container) > picture > img,
.cardHeader6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.title6 {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.icon6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-JYEEwSwHupuU);
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.content6 {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 28px;
}
.content6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content6 > :global(.__wab_flex-container) > *,
.content6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content6 > :global(.__wab_flex-container) > picture > img,
.content6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.text6 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__ws1Ys:global(.__wab_instance) {
  max-width: 100%;
}
.svg__twQqx {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__f5Hq3 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
