.splashImage {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: auto;
  min-width: 0;
}
.splashImage > picture > img {
  object-fit: cover;
}
