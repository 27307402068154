.cardContent {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  min-width: 0;
  min-height: 0;
  border-radius: 16px;
  padding: 4px;
  border: 1px solid var(--token-7u3Fjf0ZO4pV);
}
.freeBox__zirLo {
  background: linear-gradient(#00294ce6, #00294ce6),
    url("https://site-assets.plasmic.app/b9c12b6aaca0e25fec289889d58d2cf9.jpg")
      center center / cover no-repeat;
  height: 192px;
  display: flex;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 12px;
  padding: 28px;
}
.freeBox__zirLo > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__zirLo > :global(.__wab_flex-container) > *,
.freeBox__zirLo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zirLo > :global(.__wab_flex-container) > picture > img,
.freeBox__zirLo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.h4__yQaql {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.svg__sQoS {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #8bcdf3;
  width: 32px;
  height: 32px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.freeBox__uyYfI {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: 28px;
}
.freeBox__uyYfI > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__uyYfI > :global(.__wab_flex-container) > *,
.freeBox__uyYfI > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__uyYfI > :global(.__wab_flex-container) > picture > img,
.freeBox__uyYfI
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.p__cnPtZ {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.button__iw7VZ:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kCxQ6 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__aYj3G {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
