.root {
  object-fit: cover;
  max-width: 100%;
  width: 113.777px;
  height: 19.887px;
  display: block !important;
  overflow: hidden;
  position: relative;
  cursor: auto;
  filter: none;
}
.root > picture > img {
  object-fit: cover;
}
.rootproduct_myCme {
  width: 54px;
  justify-self: flex-start;
  height: auto;
}
.rootproduct_myGme {
  width: 97.5px;
  justify-self: flex-start;
  height: auto;
}
.rootgrayscale {
  filter: grayscale(100%);
}
.rootinteractable {
  cursor: pointer;
}
.rootproduct_myEvaluations_grayscale {
  filter: grayscale(100%);
}
.rootgrayscale_product_myCme {
  filter: grayscale(100%);
}
.rootgrayscale_product_myGme {
  filter: grayscale(100%);
}
