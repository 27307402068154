.caseStudies {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: var(--token-9T9NC2lhv1Ej);
  position: relative;
  min-width: 0;
}
.caseStudies > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
@media (max-width: 1024px) {
  .caseStudies {
    background: var(--token-9T9NC2lhv1Ej);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudies {
    padding: 64px 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudies > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 64px);
    height: calc(100% + 64px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .caseStudies > :global(.__wab_flex-container) > *,
  .caseStudies > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .caseStudies > :global(.__wab_flex-container) > picture > img,
  .caseStudies
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 64px;
  }
}
.desktop {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  min-width: 0;
  padding: 96px 0px;
}
.desktop > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.desktop > :global(.__wab_flex-container) > *,
.desktop > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desktop > :global(.__wab_flex-container) > picture > img,
.desktop
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
.img {
  position: absolute;
  object-fit: fill;
  max-width: 100%;
  top: -175px;
  width: 1200px;
  height: 900px;
  right: -400px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: fill;
}
.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 1216px;
  min-width: 0;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.row {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
}
.row > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.row > :global(.__wab_flex-container) > *,
.row > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row > :global(.__wab_flex-container) > picture > img,
.row > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (max-width: 1024px) {
  .row {
    width: 100%;
    min-width: 0;
  }
}
@media (max-width: 1024px) {
  .row > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.sectionHeader:global(.__wab_instance) {
  max-width: 560px;
  position: relative;
  width: 100%;
  min-width: 0;
}
@media (max-width: 1224px) {
  .sectionHeader:global(.__wab_instance) {
    max-width: 45%;
  }
}
@media (max-width: 1024px) {
  .sectionHeader:global(.__wab_instance) {
    width: 100%;
    max-width: 475px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .sectionHeader:global(.__wab_instance) {
    max-width: 560px;
  }
}
.bannerVariantProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.bannerLabelProvider:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.banner {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.banner > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.banner > :global(.__wab_flex-container) > *,
.banner > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.banner > :global(.__wab_flex-container) > picture > img,
.banner
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.nav {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  min-width: 0;
}
.nav > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.nav > :global(.__wab_flex-container) > *,
.nav > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.nav > :global(.__wab_flex-container) > picture > img,
.nav > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 8px;
}
.left {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.left > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.left > :global(.__wab_flex-container) > *,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.left > :global(.__wab_flex-container) > picture > img,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.navBackButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__fouXh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
}
.navForwardButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__daIjG {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
}
.allCaseStudiesButton:global(.__wab_instance) {
  position: relative;
}
.svg__vcw6E {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg___30Xq {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.bannerContainer {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 0px;
}
.bannerContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.bannerContainer > :global(.__wab_flex-container) > *,
.bannerContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bannerContainer > :global(.__wab_flex-container) > picture > img,
.bannerContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.sliderCarousel:global(.__wab_instance) {
  width: auto;
  max-width: 100%;
  flex-direction: column;
  object-fit: cover;
  position: relative;
  height: auto;
  background: none;
}
.freeBox__drdSu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0px 16px;
}
.homeCaseStudiesSectionBanner__ypcO:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox___5ME2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0px 16px;
}
.homeCaseStudiesSectionBanner___1OTky:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: auto;
  min-width: 0;
}
.freeBox__uFkPq {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0px 16px;
}
.homeCaseStudiesSectionBanner___2OdIq:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: auto;
  min-width: 0;
}
.sliderNavigation {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.sliderNavigation > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.sliderNavigation > :global(.__wab_flex-container) > *,
.sliderNavigation > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sliderNavigation > :global(.__wab_flex-container) > picture > img,
.sliderNavigation
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.navigationBullet:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navigationBullet:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.buttonWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  min-width: 0;
  padding: 0px 28px;
}
.allCaseStudiesButton2:global(.__wab_instance) {
  position: relative;
  align-self: center;
  width: 100%;
  min-width: 0;
}
.svg__qr8Y {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.svg__wOk2J {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
