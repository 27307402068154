.quote {
  box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  height: auto;
  width: 100%;
  background: linear-gradient(rgba(0, 99, 43, 0.8), rgba(0, 99, 43, 0.8)),
    url("https://site-assets.plasmic.app/bf28f2c2c3336007c9e98c2dcfe35c44.jpg")
      left 50% top 50% / cover no-repeat;
  min-width: 0;
  border-radius: 16px;
  padding: 32px;
}
.quote > :global(.__wab_flex-container) {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.quote > :global(.__wab_flex-container) > *,
.quote > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.quote > :global(.__wab_flex-container) > picture > img,
.quote > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .quote {
    height: auto;
    min-height: 604px;
  }
}
.top {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.top > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.top > :global(.__wab_flex-container) > *,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top > :global(.__wab_flex-container) > picture > img,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
.header {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.header > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.quoteLogo {
  opacity: 0.8;
  width: 96px;
  height: 48px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.mercyHealthLogo {
  object-fit: cover;
  max-width: 100%;
  position: absolute;
  left: 0px;
  top: -27.09%;
  width: 156.25%;
  height: 156.25%;
}
.mercyHealthLogo > picture > img {
  object-fit: cover;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #55ff9e;
  width: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
.title4 {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  align-self: stretch;
  position: relative;
}
.author {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  padding: 100px 0px 0px;
}
.author > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.author > :global(.__wab_flex-container) > *,
.author > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.author > :global(.__wab_flex-container) > picture > img,
.author
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 1024px) {
  .author {
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .author {
    padding-top: 100px;
  }
}
.av {
  background: url("https://site-assets.plasmic.app/4fb120dc2512376476c25583343aded7.jpg")
    center center / cover no-repeat;
  box-shadow: inset 0px 0px 0px 2px #ffffff80;
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  flex-shrink: 0;
  border-radius: 64px;
}
.name {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-9T9NC2lhv1Ej);
  display: block;
  flex-grow: 1;
  position: relative;
}
.button4:global(.__wab_instance) {
  height: 40px;
  position: relative;
  align-self: center;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
.svg__sU9Go {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  text-align: center;
  align-self: center;
  width: 100%;
  height: auto;
  min-width: 0;
}
.svg__ogadu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
