.accordion {
  box-shadow: 0px 4px 20px -4px #0f2a4126, 0px 2px 6px -3px #0f2a411a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  position: relative;
  min-width: 0;
  border-radius: 16px;
  border: 1px solid var(--token-NagwnEgqYDVz);
}
.accordionSection__hNsl1:global(.__wab_instance) {
  max-width: 100%;
}
.accordionSectionHeader__z2BQk:global(.__wab_instance) {
  position: relative;
}
.freeBox___3TgjF {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px;
  border-bottom: 1px solid var(--token-NagwnEgqYDVz);
}
.freeBox___3TgjF > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox___3TgjF > :global(.__wab_flex-container) > *,
.freeBox___3TgjF > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3TgjF > :global(.__wab_flex-container) > picture > img,
.freeBox___3TgjF
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox__d7QJe {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  background: url("https://site-assets.plasmic.app/586ae0424acd4b66ca75fce77ee81fa1.svg")
    top 50% left 50% / cover repeat;
  width: 100%;
  height: 244px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 8px;
}
.freeBox___2VLm {
  display: flex;
  flex-direction: column;
  position: relative;
}
.freeBox___2VLm > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___2VLm > :global(.__wab_flex-container) > *,
.freeBox___2VLm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___2VLm > :global(.__wab_flex-container) > picture > img,
.freeBox___2VLm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.h4__a3WFt {
  font-family: var(--rubik);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--token-vF1ggEL4NG72);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.p__dGxlF {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox___3XQql {
  display: flex;
  flex-direction: column;
  position: relative;
}
.freeBox___3XQql > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox___3XQql > :global(.__wab_flex-container) > *,
.freeBox___3XQql > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3XQql > :global(.__wab_flex-container) > picture > img,
.freeBox___3XQql
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.h6___1SVMl {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__ar5Xv {
  display: flex;
  flex-direction: row;
  position: relative;
}
.freeBox__ar5Xv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.freeBox__ar5Xv > :global(.__wab_flex-container) > *,
.freeBox__ar5Xv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ar5Xv > :global(.__wab_flex-container) > picture > img,
.freeBox__ar5Xv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.text___0Ovtv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul__rnSdh {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
.text__xDvSw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.ul___2QYe2 {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
}
