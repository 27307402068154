.header {
  box-shadow: 0px 2px 8px 0px #0f2a410f, 0px 1px 2px -1px #0f2a410a;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: var(--token-w1v4JEx-uq2e);
  position: relative;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.3s;
  border-bottom-width: 0px;
  min-width: 0;
  -webkit-transition-property: color;
  -webkit-transition-duration: 0.3s;
  padding: 16px 32px;
  border-top: 1px solid var(--token-NagwnEgqYDVz);
  border-right: 0px solid var(--token-NagwnEgqYDVz);
  border-left: 0px solid var(--token-NagwnEgqYDVz);
}
.header > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .header {
    padding: 16px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .header > :global(.__wab_flex-container) {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .header > :global(.__wab_flex-container) > *,
  .header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .header > :global(.__wab_flex-container) > picture > img,
  .header
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.headeractive {
  background: var(--token-F-isEiSy-7QO);
  cursor: auto;
  border-bottom: 1px solid var(--token-eqvPWoOG9mbc);
}
.headerorder_first {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-top-width: 0px;
}
.headerorder_last {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-bottom-style: solid;
  border-color: var(--token-NagwnEgqYDVz);
}
.headeractive_order_last {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-width: 1px;
  border-bottom-color: var(--token-eqvPWoOG9mbc);
}
.header:hover {
  background: var(--token-7u3Fjf0ZO4pV);
}
.headeractive:hover {
  background: var(--token-F-isEiSy-7QO);
  border-bottom-color: var(--token-eqvPWoOG9mbc);
}
.text {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-width: 0;
}
.text > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 4px);
    height: calc(100% + 4px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text > :global(.__wab_flex-container) > *,
  .text > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .text > :global(.__wab_flex-container) > picture > img,
  .text
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 4px;
  }
}
.myEvaluationsLogo:global(.__wab_instance) {
  max-width: 100%;
  width: 189.63px;
  height: 33.15px;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 4.44px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .myEvaluationsLogo:global(.__wab_instance) {
    left: auto;
    top: auto;
    margin-top: calc(4.44px + 4px) !important;
    flex-shrink: 0;
  }
}
.myEvaluationsLogoactive:global(.__wab_instance) {
  flex-shrink: 0;
}
.myCmeLogo:global(.__wab_instance) {
  max-width: 100%;
  width: 90.37px;
  height: 34.58px;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 4.44px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .myCmeLogo:global(.__wab_instance) {
    left: auto;
    top: auto;
    margin-top: calc(4.44px + 4px) !important;
    flex-shrink: 0;
  }
}
.myGmeLogo:global(.__wab_instance) {
  max-width: 100%;
  width: 162.96px;
  height: 33.35px;
  position: relative;
  left: auto;
  top: auto;
  margin-top: 5.93px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .myGmeLogo:global(.__wab_instance) {
    left: auto;
    top: auto;
    margin-top: calc(5.93px + 4px) !important;
    flex-shrink: 0;
  }
}
.right {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .right {
    justify-content: flex-start;
  }
}
.desc {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-sJr9sbZwbyqK);
  position: relative;
  width: 280px;
  height: auto;
  max-width: 100%;
  text-align: right;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .desc {
    text-align: left;
    width: 100%;
    max-width: 280px;
    min-width: 0;
  }
}
.descactive {
  color: var(--token-pkN2y4iq-viG);
}
.btn {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .btn {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
  }
}
.roundButton:global(.__wab_instance) {
  max-width: 100%;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.arrowIcon {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  width: 100%;
  height: 100%;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-90deg);
  transition-property: transform;
  transition-duration: 0.3s;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.3s;
}
.arrowIconactive {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
