.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 12px;
  justify-self: flex-start;
  height: 12px;
  border: 0px solid transparent;
}
.sliderNavigationBullet {
  background: var(--token-7u3Fjf0ZO4pV);
  width: 12px;
  height: 12px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 64px;
}
.sliderNavigationBulletactive {
  background: var(--token-pkN2y4iq-viG);
}
.root:hover .sliderNavigationBullet {
  background: var(--token-NagwnEgqYDVz);
}
.root:active .sliderNavigationBullet {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  background: var(--token-NagwnEgqYDVz);
}
.rootactive:hover .sliderNavigationBulletactive {
  background: var(--token-gaowRD3VsD3w);
}
.rootactive:active .sliderNavigationBulletactive {
  background: var(--token-gaowRD3VsD3w);
  box-shadow: inset 0px 1px 4px 2px #0f2a4133;
}
