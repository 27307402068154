.sectionHeader {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 0;
}
.sectionHeader > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.sectionHeader > :global(.__wab_flex-container) > *,
.sectionHeader > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.sectionHeader > :global(.__wab_flex-container) > picture > img,
.sectionHeader
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .sectionHeader {
    padding: 0px 16px;
  }
}
.top {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.top > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.top > :global(.__wab_flex-container) > *,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.top > :global(.__wab_flex-container) > picture > img,
.top > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.subtitleText {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  align-self: stretch;
  position: relative;
}
.titleText {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-vF1ggEL4NG72);
  display: block;
  align-self: stretch;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .titleText {
    line-height: 32px;
    font-size: 24px;
  }
}
.descriptionText {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  width: 100%;
  min-width: 0;
}
