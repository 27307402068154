.root {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-pkN2y4iq-viG);
  cursor: pointer;
  transition-property: background;
  transition-duration: 0.1s;
  width: auto;
  height: auto;
  justify-self: flex-start;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.1s;
  border-radius: 64px;
  padding: 10px;
  border-width: 0px;
}
.rootisDisabled {
  cursor: not-allowed;
  opacity: 1;
  background: var(--token-NagwnEgqYDVz);
}
.rootsize_small {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  width: auto;
  height: auto;
  justify-self: flex-start;
  display: inline-flex;
}
.rootsize_medium {
  width: auto;
  height: auto;
  justify-self: flex-start;
  display: inline-flex;
  padding: 14px;
}
.rootsize_large {
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  display: inline-flex;
  padding: 16px;
}
.rootcolor_primary {
  background: var(--token-pkN2y4iq-viG);
}
.rootcolor_sky {
  background: var(--token-F-isEiSy-7QO);
}
.rootcolor_gray {
  background: var(--token-7u3Fjf0ZO4pV);
}
.rootnotInteractable {
  pointer-events: none;
  cursor: auto;
}
.rootcolor_primary_isDisabled {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_sky_isDisabled {
  background: var(--token-NagwnEgqYDVz);
}
.rootisDisabled_color_gray {
  background: var(--token-NagwnEgqYDVz);
}
.root:hover {
  background: #282826;
}
.root:active {
  background: #2e2e2b;
}
.rootcolor_primary:hover {
  background: var(--token-gaowRD3VsD3w);
}
.rootcolor_primary:active {
  box-shadow: inset 0px 1px 4px 2px #0f2a4133;
  background: var(--token-gaowRD3VsD3w);
}
.rootcolor_sky:hover {
  background: var(--token-eqvPWoOG9mbc);
}
.rootcolor_sky:active {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  background: var(--token-eqvPWoOG9mbc);
}
.rootcolor_gray:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_gray:active {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_primary_isDisabled:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_primary_isDisabled:active {
  background: var(--token-NagwnEgqYDVz);
  box-shadow: none;
}
.rootcolor_sky_isDisabled:hover {
  background: var(--token-NagwnEgqYDVz);
}
.rootcolor_sky_isDisabled:active {
  background: var(--token-NagwnEgqYDVz);
  box-shadow: none;
}
.rootisDisabled_color_gray:active {
  box-shadow: none;
}
.buttonContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.buttonContainersize_small {
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-width: 0px;
}
.buttonContainersize_medium {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}
.buttonContainersize_large {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.slotTargetIcon {
  color: var(--token-F-isEiSy-7QO);
}
.slotTargetIconisDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetIconcolor_sky {
  color: var(--token-vF1ggEL4NG72);
}
.slotTargetIconcolor_gray {
  color: var(--token-Uy2x1Cuw6kvC);
}
.slotTargetIconcolor_sky_isDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.slotTargetIconisDisabled_color_gray {
  color: var(--token-sJr9sbZwbyqK);
}
.rootcolor_sky_isDisabled:hover .slotTargetIconcolor_sky_isDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.rootcolor_sky_isDisabled:active .slotTargetIconcolor_sky_isDisabled {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_color_gray:hover .slotTargetIconisDisabled_color_gray {
  color: var(--token-sJr9sbZwbyqK);
}
.rootisDisabled_color_gray:active .slotTargetIconisDisabled_color_gray {
  color: var(--token-sJr9sbZwbyqK);
}
.svg__pHGrg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
