.item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  cursor: pointer;
  min-width: 0;
  border-radius: 8px;
  padding: 8px 12px;
}
.item > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.item > :global(.__wab_flex-container) > *,
.item > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.item > :global(.__wab_flex-container) > picture > img,
.item > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.itemactive {
  background: var(--token-F-isEiSy-7QO);
}
.item:hover {
  background: var(--token-w1v4JEx-uq2e);
}
.item:active {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
  background: var(--token-w1v4JEx-uq2e);
}
.itemactive:hover {
  background: #8bcdf380;
}
.itemactive:active {
  background: #8bcdf380;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 128px;
}
.img {
  background: url("https://site-assets.plasmic.app/2201c4034f05b5f19f675985564ce6d2.jpg")
    center center / cover no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
}
.imgpov_officer {
  background: url("https://site-assets.plasmic.app/3c7f99cd86da7768cab0b7ddcd1773ca.svg")
    center center / cover no-repeat;
}
.imgpov_administrator {
  background: url("https://site-assets.plasmic.app/b3f05cd4bacd09e79beef0f8e3057194.jpg")
    center center / cover no-repeat;
}
.svg {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #81e733;
  mix-blend-mode: hue;
  width: 40px;
  height: 40px;
  display: block;
  top: 0%;
  left: 0%;
}
.svgpov_officer {
  color: #8bcdf380;
}
.svgpov_administrator {
  color: #dd4d41;
}
.text {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.role {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  user-select: none;
}
.roleactive {
  color: var(--token-vF1ggEL4NG72);
}
.name {
  color: var(--token-sJr9sbZwbyqK);
  font-family: var(--rubik);
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
  user-select: none;
}
.nameactive {
  color: var(--token-pkN2y4iq-viG);
}
